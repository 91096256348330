import Img1 from "../images/signtalk.jpg";
import Img2 from "../images/anubis.png";
import Img3 from "../images/blockchain.jpg";
import Img4 from "../images/call_away1.jpg";
import Img5 from "../images/license.jpg";
import Img6 from "../images/admo_choice.png";
import Img7 from "../images/farmpal.jpg";
import Img8 from "../images/traffic3.jpg";
import OverImg1 from "../images/overlay/signtalk.png";
import OverImg2 from "../images/overlay/anubis.png";
import OverImg3 from "../images/overlay/blockchain.png";
import OverImg4 from "../images/overlay/callaway.png";
import OverImg5 from "../images/overlay/numberplate.png";
import OverImg6 from "../images/overlay/myadmo.png";
import OverImg7 from "../images/overlay/farmpal.png";
import OverImg8 from "../images/overlay/trafficpolice.png";

export const ProjectData = [
  {
    title: "SignTalk",
    img: Img1,
    over: OverImg1,
    alt: "SignTalk Image",
    description:
      "SignTalk is sign language translation system that allows deaf and dumb patients to communicate effectively with their doctors by using the power of computer vision and deep learning. It is capable of both translation of speech-to-sign-language and sign-language-to-speech.",
    qid: "1",
    waitlist: "https://forms.gle/Pd9T94T9XdHfVDBUA",
    email: "eahene@gmail.com",
    phone: "+233 (0) 20 343 4503",
  },
  {
    title: "Anubis Network Intrusion detection system",
    img: Img2,
    over: OverImg2,
    alt: "Anubis Network Intrusion Detection System Image",
    description:
      "Anubis network intrusion detection system (aNIDS) keeps track of all network activity, including data transfer between computers and inbound and outbound traffic. The network IDS keeps track of network traffic and sends alerts when it notices unusual behavior or well-known dangers such as spoofing, allowing users to investigate further and take the necessary precautions to thwart or halt an attack.",
    qid: "2",
    waitlist: "https://forms.gle/C1mLstsXJ1voYvib8",
    email: "eahene@gmail.com",
    phone: "+233 (0) 20 343 4503",
  },
  {
    title: "Blockchain based electronic voting system.",
    img: Img3,
    over: OverImg3,
    alt: "Blockchain E-Voting System Image",
    description:
      "An electronic voting system based on the block chain technology that ensures transparency, fairness, accuracy and decentralisation of an election process.",
    qid: "3",
    waitlist: "https://forms.gle/xcQEn1UtLUi45UFo8",
    email: "eahene@gmail.com",
    phone: "+233 (0) 20 343 4503",
  },
  {
    title: "Call Away",
    img: Img4,
    over: OverImg4,
    alt: "Call Away Image",
    description:
      "A report management system aimed to make issuing and managing reports more convenient.",
    qid: "4",
    waitlist: "https://forms.gle/D7wHvuPT6XirpH8f9",
  },
  {
    title: "Number Plate Recognition System",
    img: Img5,
    over: OverImg5,
    alt: "Number Plate Recognition System Image",
    description:
      "The project utilizes image processing technology in order to extract and recognize license plate information from an image or video frames. Based on the extracted texts from the license plate, we fetch information from the database to determine whether your vehicle is roadworthy.",
    qid: "5",
    waitlist: "https://forms.gle/dN4c1wHfrtWFSHjT7",
    email: "eahene@gmail.com",
    phone: "+233 (0) 20 343 4503",
  },
  {
    title: "myAdMo",
    img: Img6,
    over: OverImg6,
    alt: "myAdMo Image",
    description:
      "An adblocker extension that not only blocks ads but gives users points for the ads they view, points they can use to purchase products in a marketplace provided by myAdMo. Advertise can also sell the products they advertise through myAdMo.",
    qid: "6",
    waitlist: "https://forms.gle/KPnRokJr3SvajU9b7",
    email: "eahene@gmail.com",
    phone: "+233 (0) 20 343 4503",
  },
  {
    title: "FarmPal",
    img: Img7,
    over: OverImg7,
    alt: "FarmPal Image",
    description:
      "FarmPal is a smart farm system that provides live climatic and soil condition updates as well as other useful systems such as the intelligent irrigation system, intelligent fire prevention",
    qid: "7",
    waitlist: "https://forms.gle/NHEjG67jV1Z6qpyJ9",
    email: "eahene@gmail.com",
    phone: "+233 (0) 20 343 4503",
  },
  {
    title: "Traffic Police App",
    img: Img8,
    over: OverImg8,
    alt: "Traffic Police Image",
    description:
      "Traffic police App focuses on digitalizing the Manual reporting procedure of road violators and also gives the violator an opportunity to make payment instantly.",
    qid: "8",
    waitlist: "https://forms.gle/9BZufPo5rL5QuNEw8",
    email: "eahene@gmail.com",
    phone: "+233 (0) 20 343 4503",
  },
];
