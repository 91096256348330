import "../css/Home.css";

import { ProjectData } from "../data/ProjectData.js";

import { Link } from "react-router-dom";

import LogoImg from "../images/csplogo.png";
import HeroImg from "../images/illustration.jpg";
import WDotsImg from "../images/3dots.png";

import { useState, useEffect } from "react";

const Home = () => {
  const [windowWidth, setWindowWidth] = useState("");

  function resizeListener() {
    setWindowWidth(window.innerWidth);
  }

  function reveal() {
    var reveals = document.querySelectorAll(".img-change");

    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 0;

      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("animated1");
      } else {
        reveals[i].classList.remove("animated");
      }
    }
  }

  useEffect(() => {
    resizeListener();
    window.addEventListener("resize", resizeListener);
    reveal();
    window.addEventListener("scroll", reveal);
  });

  var changeImage = false;

  if (windowWidth < 991) {
    changeImage = true;
  } else {
    changeImage = false;
  }

  return (
    <div>
      <nav className="navbar nav-content text-center">
        <Link to="/" className="homelink">
          <span className="logoname">
            <img src={LogoImg} alt="CSP logo" className="navlogo" />
            <h2 className="navlogo-name">CS Projects</h2>
          </span>
        </Link>
      </nav>
      <section id="hero" class="d-flex align-items-center section1">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center welcome">
              <h1 data-aos="fade-up">Welcome to CS Projects</h1>
              <h2 data-aos="fade-up" data-aos-delay="400">
                Here are some awesome projects by computer science students from
                KNUST.
              </h2>
              <div data-aos="fade-up" data-aos-delay="800">
                <a href="#projects" class="btn-get-started scrollto">
                  Get Started
                </a>
              </div>
            </div>
            <div
              class="col-lg-6 order-1 order-lg-2 hero-img"
              data-aos="fade-left"
              data-aos-delay="200"
            >
              <a href="http://www.freepik.com">
                <img src={HeroImg} class="img-fluid animated" alt="" />
              </a>
            </div>
          </div>
        </div>
      </section>
      <div className="pro-bar text-center">
        <span className="pro-header" id="projects">
          <img src={WDotsImg} alt="CSP logo" className="dots" />
          <h2 className="pro-header-title">The Projects</h2>
          <img src={WDotsImg} alt="CSP logo" className="dots flip" />
        </span>
      </div>
      <section id="hero1" class="d-flex align-items-center">
        <div class="">
          {ProjectData.map((item, index) => {
            return (
              <div key={item.qid}>
                <div class="row pro-row">
                  <div class="col-lg-5 pt-5 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center pro-text">
                    <h1 data-aos="fade-up">{item.title}</h1>
                    <h2 data-aos="fade-up" data-aos-delay="400">
                      {item.description}
                    </h2>
                    <div data-aos="fade-up" data-aos-delay="800">
                      <div className="btns">
                        <button
                          type="button"
                          class="btn btn-warning btn-size"
                          data-bs-toggle="modal"
                          data-bs-target={`#exampleModal${item.qid}`}
                        >
                          Ask a question
                        </button>

                        <div
                          class="modal fade"
                          id={`exampleModal${item.qid}`}
                          tabindex="-1"
                          aria-labelledby={`exampleModalLabel${item.qid}`}
                          aria-hidden="true"
                        >
                          <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                              <div class="modal-header">
                                <h5
                                  class="modal-title"
                                  id={`exampleModalLabel${item.qid}`}
                                >
                                  Ask Your Question?
                                </h5>
                                <button
                                  type="button"
                                  class="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                ></button>
                              </div>
                              <div class="modal-body modal-content">
                                <form
                                  action="https://formsubmit.co/eahene@gmail.com"
                                  method="POST"
                                >
                                  <input
                                    type="hidden"
                                    name="_subject"
                                    value={`Question concerning ${item.title}`}
                                  />
                                  <div class="mb-3">
                                    <label
                                      for="exampleFormControlInput1"
                                      class="form-label"
                                    >
                                      Name:
                                    </label>
                                    <input
                                      type="text"
                                      name="name"
                                      class="form-control"
                                      id="exampleFormControlInput1"
                                      placeholder=""
                                      required
                                    />
                                  </div>
                                  <div class="mb-3">
                                    <label
                                      for="exampleFormControlInput2"
                                      class="form-label"
                                    >
                                      Email address
                                    </label>
                                    <input
                                      type="email"
                                      name="email"
                                      class="form-control"
                                      id="exampleFormControlInput2"
                                      placeholder=""
                                      required
                                    />
                                  </div>
                                  <div class="mb-3">
                                    <label
                                      for="exampleFormControlTextarea1"
                                      class="form-label"
                                    >
                                      Question
                                    </label>
                                    <textarea
                                      type="hidden"
                                      name="message"
                                      class="form-control"
                                      id="exampleFormControlTextarea1"
                                      rows="3"
                                      required
                                    ></textarea>
                                    <button
                                      type="submit"
                                      class="btn btn-primary mt-4"
                                    >
                                      Submit
                                    </button>
                                  </div>
                                </form>
                              </div>
                              <div class="modal-footer">
                                <button
                                  type="button"
                                  class="btn btn-secondary"
                                  data-bs-dismiss="modal"
                                >
                                  Close
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <a
                          href={item.waitlist}
                          class="btn btn-success btn-size"
                        >
                          Join the waitlist
                        </a>

                        <button
                          type="button"
                          class="btn contactus btn-size"
                          data-bs-toggle="modal"
                          data-bs-target={`#exampleModalTwo${item.qid}`}
                        >
                          Contact Us
                        </button>

                        <div
                          class="modal fade"
                          id={`exampleModalTwo${item.qid}`}
                          tabindex="-1"
                          aria-labelledby={`exampleModalTwoLabel${item.qid}`}
                          aria-hidden="true"
                        >
                          <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                              <div class="modal-header">
                                <h5
                                  class="modal-title"
                                  id={`exampleModalTwoLabel${item.qid}`}
                                >
                                  Contact Information
                                </h5>
                                <button
                                  type="button"
                                  class="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                ></button>
                              </div>
                              <div class="modal-body modal-content">
                                <div>Email: {item.email}</div>
                                <div>Phone Number: {item.phone}</div>
                              </div>
                              <div class="modal-footer">
                                <button
                                  type="button"
                                  class="btn btn-secondary"
                                  data-bs-dismiss="modal"
                                >
                                  Close
                                </button>
                                {/* <button type="button" class="btn btn-primary">Save changes</button> */}
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <Link to="/" class="btn btn-size readmore">
                          Read More
                          <BsIcons.BsArrowRightShort />
                        </Link> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-1"></div>
                  <div
                    class="col-lg-6 order-1 order-lg-2 hero-img1"
                    data-aos="fade-left"
                    data-aos-delay="200"
                  >
                    <img
                      src={changeImage ? item.img : item.over}
                      class="img-fluid img-change"
                      alt={item.alt}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </div>
  );
};

export default Home;
